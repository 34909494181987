function NotFound() {
  return (
    <div>
      <h1>Page not found.</h1>
      <p>
        Please re-check the URL or contact page administrator if problem
        persists.
      </p>
    </div>
  );
}

export default NotFound;
