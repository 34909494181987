import { Search } from "@material-ui/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GENERAL_SECTIONS } from "../../api/contentsEndpoints";
import { Profile } from "../../constants/types";
import { Container, DrawerLink, DrawerLinkContainer } from "./styles/drawer";
import pepAxios from "../../api/contents";
import {
  useFocusable,
  FocusContext,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableComponent from "../../containers/focusableComponent";
import AudioPlayer from "../audioPlayer";
import TranslatedText from "../TranslatedText";
import { useSelector } from "react-redux";
import { ReducerGraphic, Reducers } from "../../types/reducers";
import Loader from "../Loader";
import { privacyPolicyUrl } from "../../containers/newsletterBanner";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { use } from "i18next";

interface Props {
  setFocusFlag: boolean;
  profile: Profile;
  activeCategory: string;
  activeCatalogId: number;
  setActiveCatalog?: (id: number) => void;
  setCategory?: (category: string) => void;
  setSearchIsActive?: (isActive: boolean) => void;
  setLivestreamIsActive?: (isActive: boolean) => void;
  clearCurrentUser: () => void;
  onCollapseChange?: (collapsed: boolean) => void;
}

const Drawer: React.FC<Props> = (props) => {
  const [searchPanelIsVisible, setSearchPanelIsVisible] = useState(false);
  const [livestreamIsVisible, setLivestreamIsVisible] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [catalogs, setCatalogs] = useState<{ title: string; id: number }[]>([]);
  const [validCatalogs, setValidCatalogs] = useState<
    { title: string; id: number }[]
  >([]);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const [lastTimeout, setLastTimeout] = useState<NodeJS.Timeout | null>(null);

  const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 768);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mobileCheck = window.innerWidth <= 768;
      setIsMobile(mobileCheck);
      if (!mobileCheck) {
        // If switching back to desktop from mobile, ensure drawer is expanded
        setIsCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const eventsToListen = [
    "keydown",
    "mouseover",
    "mousemove",
    "touchstart",
    "touchmove",
  ];
  const { ref, focusKey, focusSelf, navigateByDirection } = useFocusable({
    focusable: true,
    saveLastFocusedChild: false,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
  });

  let timeout: NodeJS.Timeout | null = null;

  const restartTimer = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (!livestreamIsVisible) return;
    setLastTimeout(timeout);
    setIsHidden(false);
    // eslint-disable-next-line
    timeout = setTimeout(() => {
      setIsHidden(true);
    }, 3000);
    setLastTimeout(timeout);
  }, [livestreamIsVisible]);

  useEffect(() => {
    if (livestreamIsVisible) return;
    setIsHidden(false);
    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }
  }, [livestreamIsVisible, lastTimeout]);

  useEffect(() => {
    eventsToListen.forEach((eventKey) => {
      window.addEventListener(eventKey, restartTimer);
    });
    return () => {
      eventsToListen.forEach((eventKey) => {
        window.removeEventListener(eventKey, restartTimer);
      });
    };
  }, [restartTimer]);

  const parseOrgParameter = useMemo(() => {
    const urlString = new URLSearchParams(window.location.search);
    return urlString.get("idOrg") || "55";
  }, []);

  useEffect(() => {
    navigateByDirection("down", { event: undefined });
  }, [navigateByDirection]);

  useEffect(() => {
    if (!props.setFocusFlag) return;
    focusSelf();
  }, [focusSelf, props.setFocusFlag]);

  useEffect(() => {
    if (catalogs.length > 0 || parseOrgParameter === "") return;
    getCatalogs();
  }, [parseOrgParameter, catalogs.length]);

  const getCatalogs = async () => {
    const fetchedCatalogs = await pepAxios
      .get(
        GENERAL_SECTIONS.helpers.fetchCatalogs.replace(
          "{{org_id}}",
          parseOrgParameter
        )
      )
      .then((response) => response.data);

    setCatalogs(
      fetchedCatalogs.items.map((catalog: any) => {
        return { title: catalog.title, id: catalog.id };
      })
    );
  };

  useEffect(() => {
    if (validCatalogs.length === 0 && props.activeCatalogId !== -1) {
      if (props.setActiveCatalog) props.setActiveCatalog(-1);
      return;
    }

    let timer: NodeJS.Timeout | undefined;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const catalogsToDisplay = sortValidCatalogs(validCatalogs);
      if (props.setActiveCatalog) {
        if (props.activeCatalogId === -1) {
          props.setActiveCatalog(-1);
        }
        if (props.activeCatalogId !== -1) {
          props.setActiveCatalog(props.activeCatalogId);
        } else if (catalogsToDisplay[0]) {
          props.setActiveCatalog(catalogsToDisplay[0].id);
        }
      }
    }, 30);
  }, [validCatalogs, props.activeCatalogId, props.setActiveCatalog]);

  useEffect(() => {
    if (catalogs.length === 0 || !catalogs) return;

    setValidCatalogs([]);

    fetchContents();
  }, [catalogs, graphic.currentLanguage]);

  const fetchContents = async () => {
    let savedCatalogs: any = [];
    const bodyParams = {
      filters: {
        categories: [],
        tags: null,
        guests: null,
        query: "",
      },
    };

    setIsLoading(true);

    for (let i = 0; i < catalogs.length; i++) {
      const catalog = catalogs[i];

      const fetchedContents = await pepAxios
        .post(
          GENERAL_SECTIONS.helpers.fetchCategoryContents.replace(
            "{{catalog_id}}",
            catalog.id.toString()
          ),
          bodyParams
        )
        .then((response) => response.data);

      const catalogValidity = fetchedContents.filter(
        (cat: any) =>
          cat.localized[graphic.currentLanguage] &&
          (cat.localized[graphic.currentLanguage].audio_url ||
            cat.localized[graphic.currentLanguage].video_url)
      );

      if (!catalogValidity.length) {
        continue;
      }

      savedCatalogs.push(catalog);
    }

    setIsLoading(false);

    setValidCatalogs(savedCatalogs);
  };

  const removeSearchView = () => {
    setLivestreamIsVisible(false);
    setSearchPanelIsVisible(false);
    if (props.setSearchIsActive) props.setSearchIsActive(false);
    if (props.setLivestreamIsActive) props.setLivestreamIsActive(false);
  };

  const truncate = (string: string, length: number) => {
    if (!string) return string;
    return string.length > length
      ? string.substr(0, length - 1) + "..."
      : string;
  };

  const sortValidCatalogs = (catalogs: any) => {
    return (
      catalogs
        .filter((catalog: any) => catalog.id !== 83)
        .sort((a: any, b: any) => (a.title < b.title ? -1 : 1))
        // 106 is the "Video" catalog id
        .sort((a: any) => (a.id === 106 ? -1 : 1))
    );
  };

  const handleToggleDrawer = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    if (props.onCollapseChange) {
      props.onCollapseChange(newCollapsedState);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(true);
    }
  }, []);

  return (
    <FocusContext.Provider value={focusKey}>
      <Container
        className={`drawer ${isHidden ? "hidden-drawer" : ""} ${
          isCollapsed && isMobile ? "collapsed" : ""
        }`}
        style={{
          background:
            (isCollapsed && isMobile) || !isMobile
              ? ""
              : "rgba(22, 21, 21, 0.94)",
        }}
      >
        <div
          style={{
            position: isMobile ? "fixed" : "absolute",
            left: isMobile ? 0 : "unset",
            right: isMobile ? 0 : "unset",
            height: 60,
            width: isMobile ? "100%" : "auto",

            overflow: "visible",
            padding: isMobile ? "8px 0" : "12px",
            top: isMobile ? 0 : 32,
            display: "flex",
            justifyContent: isMobile ? "space-between" : "center",
            alignItems: "center",
            transition: "width 0.3s ease",
            borderRadius: isMobile ? 0 : "10px",
            zIndex: 100000,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          {isMobile && (
            <button
              style={{
                background: "none",
                border: "none",
                color: "#fff",
                marginLeft: isMobile ? "32px" : "0",
                marginRight: "10px",
              }}
              onClick={handleToggleDrawer}
            >
              {isCollapsed ? <MenuIcon /> : <CloseIcon />}
            </button>
          )}

          <img
            alt="Peperoni play"
            style={{
              position: "relative",
              maxWidth: "100%",
              maxHeight: "100%",
              transition: "margin-left 0.3s ease",
              marginRight: isMobile ? "32px" : "0",
            }}
            src="/images/branding/Logo_nh_tv.png"
          />
        </div>

        {!isCollapsed || !isMobile ? (
          <DrawerLinkContainer ref={ref}>
            <FocusableComponent
              enterPressed={() => {
                setLivestreamIsVisible(false);
                setSearchPanelIsVisible(true);
                if (props.setSearchIsActive) props.setSearchIsActive(true);
                if (props.setActiveCatalog) props.setActiveCatalog(-1);
                if (props.setLivestreamIsActive)
                  props.setLivestreamIsActive(false);
              }}
            >
              <DrawerLink
                className={searchPanelIsVisible ? "active" : ""}
                onClick={() => {
                  if (isMobile) {
                    handleToggleDrawer();
                  }
                  setLivestreamIsVisible(false);
                  setSearchPanelIsVisible(true);
                  if (props.setSearchIsActive) props.setSearchIsActive(true);
                  if (props.setActiveCatalog) props.setActiveCatalog(-1);
                  if (props.setLivestreamIsActive)
                    props.setLivestreamIsActive(false);
                }}
              >
                <Search />{" "}
                <span>
                  <TranslatedText translationKey="navigation.search" />
                </span>
              </DrawerLink>
            </FocusableComponent>

            {sortValidCatalogs(validCatalogs).map(
              (
                catalog: { id: number; title: string },
                index: React.Key | null | undefined
              ) => {
                return (
                  <FocusableComponent
                    key={index}
                    enterPressed={() => {
                      setIsCollapsed(true);
                      removeSearchView();
                      if (props.setActiveCatalog)
                        props.setActiveCatalog(catalog.id);
                    }}
                  >
                    <DrawerLink
                      className={
                        props.activeCatalogId === catalog.id &&
                        !searchPanelIsVisible &&
                        !livestreamIsVisible
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        if (isMobile) {
                          handleToggleDrawer();
                        }

                        removeSearchView();
                        if (props.setActiveCatalog)
                          props.setActiveCatalog(catalog.id);
                      }}
                    >
                      <div
                        style={{
                          height: 19,
                          width: 19,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          height="100%"
                          src="https://22hbgtest.s3.eu-west-2.amazonaws.com/naturhouse/logo_white.png"
                          alt="NaturHouse"
                        />
                      </div>
                      <span>{truncate(catalog.title, 16)}</span>
                    </DrawerLink>
                  </FocusableComponent>
                );
              }
            )}
          </DrawerLinkContainer>
        ) : null}

        {isLoading && <Loader />}
        <AudioPlayer searchIsActive={searchPanelIsVisible} />

        {/* Footer Section with Privacy Policy */}
        {!isCollapsed || !isMobile ? (
          <div style={{ position: "absolute", bottom: "2rem" }}>
            <p
              style={{
                fontSize: ".8rem",
                fontFamily: "MullerBold",
                opacity: ".74",
                backgroundColor: "rgba(0,0,0,.5)",
                padding: "0.4rem 0.5rem",
                borderRadius: "1rem",
                zIndex: 1,
              }}
            >
              v{process.env.REACT_APP_VERSION}
            </p>
            <FocusableComponent
              enterPressed={() => {
                window.open(privacyPolicyUrl, "_blank");
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: -13,
                  bottom: -8,
                  width: 90,
                  height: 10,
                }}
              >
                <a
                  style={{
                    display: "flex",
                    color: "#fff",
                    fontSize: ".8em",
                    width: 90,
                  }}
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </div>
            </FocusableComponent>
          </div>
        ) : null}
      </Container>
    </FocusContext.Provider>
  );
};

export default Drawer;
