import { useState, useEffect, useRef } from "react";
import Scrollbar from "react-scrollbars-custom";
import { Details } from "../components";
import { LockBody } from "../components/loading/styles/loading";
import movieHttp from "../api/movie";
import { SECTIONS } from "../api/movieEndpoints";
import { usePlayer } from "../context/PlayerContext";
import RecommendationContainer from "./recommendation";
import { DetailsTrailer, ShowDetails, Cast, Video } from "../constants/types";
import {
  MicRounded,
  PlayArrowRounded,
  VideocamRounded,
} from "@material-ui/icons";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableComponent from "./focusableComponent";
import DetailsButtonsContainer from "./detailsButtonsContainer";
import TranslatedText from "../components/TranslatedText";

function DetailsContainer() {
  const [details, setDetails] = useState<ShowDetails | null>();
  const [cast, setCast] = useState<Array<Cast> | null>();
  const {
    playing: { setPlaying },
    category: { category },
    detailsTrailer: { detailsTrailer, setDetailsTrailer },
    isMuted: { isMuted, setIsMuted },
    heroTrailer: { setHeroTrailer },
    trailerDisplayed: { setTrailerDisplayed },
    detailsContent: { detailsContent, setDetailsContent },
  } = usePlayer();
  const [showBanner, setShowBanner] = useState(true);
  const [scrollIndex, setScrollIndex] = useState<number | undefined>(undefined);
  const modalRef = useRef<HTMLDivElement>(null);
  const playButton = useRef<HTMLDivElement>(null);
  const { ref, focusKey } = useFocusable({ isFocusBoundary: true });

  // useEffect(() => {
  //   setIsMuted(true);
  // }, []);

  useEffect(() => {
    setScrollIndex(undefined);
    setScrollIndex(0);
  }, [playButton]);

  useEffect(() => {
    if (playButton && playButton.current) playButton.current.focus();
  }, [playButton]);

  useEffect(() => {
    setHeroTrailer();
    setTrailerDisplayed(null);
  }, [setHeroTrailer, setTrailerDisplayed]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setDetailsTrailer();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDetailsTrailer]);

  useEffect(() => {
    if (detailsTrailer) {
      let detailsEndpoint;
      let creditsEndpoint;
      let trailerEndpoint;
      if (category === "series") {
        detailsEndpoint = SECTIONS.series.helpers.fetchTVDetails.replace(
          "{{tv_id}}",
          detailsTrailer.id
        );
        creditsEndpoint =
          SECTIONS.series.helpers.fetchTVAggregateCredits.replace(
            "{{tv_id}}",
            detailsTrailer.id
          );
        trailerEndpoint = SECTIONS.series.helpers.fetchTVVideos.replace(
          "{{tv_id}}",
          detailsTrailer.id
        );
      } else {
        detailsEndpoint = SECTIONS.movies.helpers.fetchMovieDetails.replace(
          "{{movie_id}}",
          detailsTrailer.id
        );
        creditsEndpoint = SECTIONS.movies.helpers.fetchMovieCredits.replace(
          "{{movie_id}}",
          detailsTrailer.id
        );
        trailerEndpoint = SECTIONS.movies.helpers.fetchMovieVideos.replace(
          "{{movie_id}}",
          detailsTrailer.id
        );
      }
      movieHttp
        .get(detailsEndpoint)
        .then((response) => setDetails(response.data))
        .catch((e) => {
          console.log(e);
          setDetails(null);
        });
      movieHttp
        .get(creditsEndpoint)
        .then((response) => setCast(response.data.cast))
        .catch((e) => console.log(e));
      if (!detailsTrailer.key && !detailsContent) {
        movieHttp
          .get(trailerEndpoint)
          .then((response) => {
            const trailerDetails: Video = response.data.results
              .reverse()
              .find(
                (video: Video) =>
                  video.site === "YouTube" && video.type === "Trailer"
              );
            if (trailerDetails)
              setDetailsTrailer((prevValue: DetailsTrailer) => ({
                ...prevValue,
                key: trailerDetails.key,
              }));
          })
          .catch((e) => console.log(e));
      }
    }
  }, [detailsTrailer, setDetailsTrailer, category, detailsContent]);

  return detailsTrailer ? (
    <FocusContext.Provider value={focusKey}>
      <Details ref={ref}>
        <LockBody />
        <Details.Inner childRef={modalRef}>
          <Scrollbar
            noDefaultStyles
            scrollTop={scrollIndex}
            className="main-scrollbar modal-scrollbar"
          >
            <div>
              {
                /** detailsTrailer.key &&
              detailsTrailer.key.indexOf(".mp4") !== -1 ? (
                <Details.Video
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  detailsTrailer={detailsTrailer}
                  setShowBanner={setShowBanner}
                />
              ) : */ detailsContent && detailsContent.backdrop_path ? (
                  // ) : detailsContent &&
                  //   detailsContent.backdrop_path &&
                  //   (showBanner ||
                  //     !detailsTrailer.key ||
                  //     detailsTrailer.key.indexOf(".mp3") !== -1) ? (
                  <Details.Banner src={detailsContent.backdrop_path} />
                ) : null
              }
              <Details.Overlay fullOverlay={!detailsTrailer} />
              <DetailsButtonsContainer>
                <>
                  <FocusableComponent
                    scrollPageIsActive
                    enterPressed={() => setDetailsTrailer()}
                  >
                    <Details.Close onClick={() => setDetailsTrailer()} />
                  </FocusableComponent>
                  <FocusableComponent
                    scrollPageIsActive
                    enterPressed={() => {
                      setDetailsTrailer();
                      setPlaying(detailsContent);
                    }}
                  >
                    <Details.Button
                      ref={playButton}
                      className="white-btn"
                      onClick={() => {
                        setDetailsTrailer();
                        setPlaying(detailsContent);
                      }}
                    >
                      <PlayArrowRounded />{" "}
                      <span>
                        <TranslatedText translationKey="detail.play" />
                      </span>
                    </Details.Button>
                  </FocusableComponent>
                </>
              </DetailsButtonsContainer>
              <Details.Summary>
                <Details.Panel className="major-details">
                  <Details.Title>
                    {detailsContent.name ||
                      detailsContent.title ||
                      detailsContent.original_name}
                  </Details.Title>
                  <p className="air-date">
                    {detailsContent.tags.map((tag: any) => (
                      <label>{tag.value}</label>
                    ))}
                  </p>
                  {/* <p className="air-date">
                    <span>
                    <TranslatedText translationKey="detail.contentCreatedOn" />{" "}
                      {new Date(
                        detailsContent.release_date
                      ).toLocaleDateString()}
                    </span>
                  </p> */}
                  <p className="overview">{detailsContent.overview}</p>
                </Details.Panel>
                <Details.Panel className="minor-details">
                  {/** cast && (
								<Details.MinorDetails>
									<span>Cast:</span>{' '}
									{cast.map(({ name }, i) => {
										if (i > 5) return null;
										return i === cast.length - 1 ? name : name + ', ';
									})}
									{cast.length > 6 && <i>more</i>}
								</Details.MinorDetails>
								) */}
                  <Details.MinorDetails>
                    <span>
                      <TranslatedText translationKey="detail.categories" />{" "}
                    </span>
                    {detailsContent.content_categories &&
                      detailsContent.content_categories.map(
                        (category: any, i: number) =>
                          i === detailsContent.content_categories.length - 1
                            ? category
                            : category + ", "
                      )}
                  </Details.MinorDetails>
                  <Details.MinorDetails>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span>
                        <TranslatedText translationKey="detail.typology" />
                      </span>
                      <div style={{ opacity: ".7", margin: ".36rem .5rem 0" }}>
                        {detailsContent.content_audio_url ? (
                          <MicRounded />
                        ) : null}
                        {detailsContent.content_video_url ? (
                          <VideocamRounded />
                        ) : null}
                      </div>
                    </div>
                  </Details.MinorDetails>
                </Details.Panel>
              </Details.Summary>
              {/** <EpisodeContainer openId={detailsTrailer.id} seasons={details.seasons} /> */}
              <RecommendationContainer
                catalog={detailsContent.catalog}
                categories={detailsContent.content_id_categories}
                openId={detailsContent.id}
              />
            </div>
          </Scrollbar>
        </Details.Inner>
      </Details>
    </FocusContext.Provider>
  ) : null;
}

export default DetailsContainer;
