import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .newsletter-alert-box {
    z-index: 4;
    position: absolute;
    bottom: 3%;
    line-height: 120%;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.8em;
    letter-spacing: 0.4px;
    font-weight: 600;
  }
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      to top,
      rgb(0 0 0 / 80%) 0,
      rgb(0 0 0 / 14%) 60%,
      rgb(0 0 0 / 80%) 100%
    ),
    url("/images/branding/nh_auth_pages_bg.png");
  background-size: cover;
  background-position: 50% 50%;
`;

export const HeaderText = styled.div`
  position: absolute;
  top: 12px;
  padding: 24px;
  width: 55rem;
  @media (max-width: 1000px) {
    width: calc(100% - 48px);
  }
`;
export const Inner = styled.div`
  width: auto;
  background: #181818;
  margin-top: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  height: 25vh;
  min-height: 340px;

  @media (max-width: 1000px) {
    width: 95vw;
    .buttons-container {
      height: 24rem;
    }
    .buttons-container > div:nth-child(2) {
      top: 18rem;
    }
  }
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh;
    margin: 0;

    .buttons-container {
      height: 18rem;
      color: #181818;
    }
    .buttons-container > div:first-child {
      top: 0.5rem;
      right: 1.5rem;
    }
    .buttons-container > div:nth-child(2) {
      top: 13rem;
    }
  }

  .newsletter-banner-body {
    width: calc(100% - 48px);
    position: relative;
    padding: 12px 24px;
    @media (max-width: 600px) {
      padding: 12px 24px;
      margin-top: 8rem;
    }
  }
  .newsletter-banner-body > div:first-child {
    width: calc(100% - 38px);
  }

  .newsletter-banner-body > input {
    width: calc(100% - 38px);
  }

  .newsletter-banner-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #fff;
      text-decoration: underline;
      font-size: 0.8rem;
      letter-spacing: 0.3px;
      font-weight: 600;
      margin-left: 4px;
    }

    button {
      font-size: 0.75rem;

      padding: 0.6rem 1rem;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      margin-top: 3rem;
      gap: 1rem;
      button {
        margin-top: 0 !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
    }
  }
`;
export const Overlay = styled.div``;

export const Title = styled.div`
  margin: 0 0 1rem;
  font-size: 2rem;
  font-family: "MullerBold";
  font-weight: normal;

  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;

export const Subtitle = styled.div`
  opacity: 0.9;
  margin: 0 0 0.5rem;
  letter-spacing: 0.4px;
  font-size: 0.98rem;
  font-family: "Muller";
  font-weight: normal;

  @media (max-width: 600px) {
    margin-top: 3.5rem;
    font-size: 1.2rem;
  }
  @media (max-width: 400px) {
    font-size: 1rem;
  }
`;
