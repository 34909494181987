import { useState } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { UserContext } from "./context/UserContext";

import "./App.css";
import "./fonts/MullerBlack.ttf";
import "./fonts/MullerBold.ttf";
import "./fonts/MullerRegular.ttf";
import "./fonts/MullerLight.ttf";

function App() {
  const FORCED_USER: any = {
    _id: "-1",
    email: "test@test.it",
    phone: "0000000000",
    profiles: [
      {
        _id: "-1",
        name: "testProfile",
        avatar: "/images/branding/pep_avatar.png",
      },
    ],
  };
  // const userJSON = localStorage.getItem('roseflix-user');
  // const [ userDetails, setUserDetails ] = useState(userJSON !== null ? JSON.parse(userJSON) : null);
  const [userDetails, setUserDetails] = useState(FORCED_USER);

  return (
    <UserContext.Provider value={{ userDetails, setUserDetails }}>
      {/* <DevelopmentConsole /> */}
      <Router>
        {Object.keys(ROUTES).map((key) => {
          const { path, page, isProtected } = ROUTES[key];
          return (
            <Route
              exact
              path={path}
              key={key}
              render={() => {
                if (isProtected && !userDetails)
                  return <Redirect to={ROUTES.SIGNIN.path} />;
                else if (!isProtected && userDetails)
                  return <Redirect to={ROUTES.BROWSE.path} />;
                else return page;
              }}
            />
          );
        })}
      </Router>
    </UserContext.Provider>
  );
}

export default App;
