import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
	-webkit-tap-highlight-color: transparent;
}
html, body {
	font-family: 'Muller', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
}
canvas {
	pointer-events: none;
}
h1 {
	font-size: 3rem;

	@media (max-width: 600px) {
		font-size: 2rem;
	}
}
.video-react .video-react-video {
	height: 100vh !important;
    max-height: 100vh;
}
.video-react-has-started .video-react-control-bar,
.video-react-big-play-button {
	display: none !important;
}
.main-scrollbar {
	width: 100vw!important;
	height: 100vh!important;
		& > .ScrollbarsCustom-Wrapper {
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			& > .ScrollbarsCustom-Scroller {
				& > .ScrollbarsCustom-Content {
				}
			}
		}

		.ScrollbarsCustom-Track {
			position: absolute;
			border-radius: 0.25rem;
		}

		.ScrollbarsCustom-Thumb {
			background: rgba(255, 255, 255, 0.12);
			cursor: pointer;
			border-radius: 0.25rem;

			&:hover,
			&.dragging {
				background: #cccccc8f;
			}
		}

		.ScrollbarsCustom-ThumbY {
			width: 100%;
		}

		.ScrollbarsCustom-ThumbX {
			height: 100%;
		}

		.ScrollbarsCustom-TrackY {
			width: 0.3rem;
			height: calc(100% - 0.5rem);
			top: 0.25rem;
			right: 0;
			background: rgba(255,255,255,0.04);
			z-index: 3;
			@media (max-width: 600px) {		
				width: 0.5rem;
			}
		}

		.ScrollbarsCustom-TrackX {
			height: 0.85rem;
			width: calc(100% - 0.5rem);
			bottom: 0;
			left: 0.25rem;
			background: rgba(255,255,255,0.04);
			z-index: 3;
			@media (max-width: 600px) {		
				height: 0.5rem;
			}
		}

		&.trackYVisible {
			& > .ScrollbarsCustom-Wrapper {
    		right: 0.3rem;
				@media (max-width: 600px) {		
					right: 0.5rem;
				}
			}

			& > .ScrollbarsCustom-TrackX {
				width: calc(100% - 0.5rem);
				left: 0.25rem;
			}
		}

		&.trackXVisible {
			&:hover {
				.ScrollbarsCustom-TrackX {
					background: #373737;
				}

				.ScrollbarsCustom-ThumbX {
					background: #4f4f4f;

					&:hover,
					&.dragging {
						background: #cccccc8f;
					}
				}
			}
		}
}
.browse {
	position: relative;
}
.modal-scrollbar {
	width: 58rem!important;
	height: 100%!important;
	@media (max-width: 1000px) {
		width: 95vw!important;
	}
	@media (max-width: 600px) {		
    width: 100vw!important;
	}
}
.arrows-buttons-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100px;
	right: 0;
	visibility: hidden;
}
.arrow-row-scroll-button {
	visibility: visible;
	cursor: pointer;
	position: absolute;
    top: 70px;
    z-index: 8;
	display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    border-radius: 50%;
	background: rgb(233,17,91);
    background: linear-gradient(90deg,rgba(233,17,91,.8) 0%,rgba(190,24,162,.8) 100%);
}
.left-scroll-button {
	left: 0
}
.right-scroll-button {
	right: 0
}
.arrow-row-scroll-button__icon {
	height: 24px;
	width: 24px;
	display: flex;
    justify-content: center;
    align-items: center;
}
`;
