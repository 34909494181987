import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import React, { useEffect, useState } from "react";

type FocusableComponentProps = {
  children?: any;
  enterPressed?: () => void;
  focusChanged?: (elementPosX: number, elementWidth: number) => void;
  scrollPageIsActive?: boolean;
};

const FocusableComponent: React.FC<FocusableComponentProps> = ({
  children,
  enterPressed,
  focusChanged,
  scrollPageIsActive,
}) => {
  const [clicked, setClicked] = useState(false);
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      if (enterPressed) enterPressed();
      setClicked(true);
    },
    onBlur: () => {
      setClicked(false);
    },
  });

  useEffect(() => {
    if (!focusChanged || !focused) return;
    const refPosX = ref.current.getBoundingClientRect().left;
    const refWidth = ref.current.getBoundingClientRect().width;
    focusChanged(refPosX, refWidth);
  }, [focused, ref]);

  useEffect(() => {
    if (!scrollPageIsActive) return;
    const scrollbars = document.getElementsByClassName(
      "ScrollbarsCustom-Scroller"
    );
    const scrollableComponent = scrollbars[1] ? scrollbars[1] : scrollbars[0];
    if (!scrollableComponent || !focused) return;

    let windowTop = scrollableComponent.scrollTop;
    let refPosY = ref.current.getBoundingClientRect().y;
    const newOffset = refPosY + (windowTop - 60);

    scrollableComponent.scrollTo({
      top: newOffset <= window.innerHeight / 1.5 ? 0 : newOffset,
      behavior: "smooth",
    });
  }, [focused, ref, scrollPageIsActive]);

  return (
    <div
      ref={ref}
      style={{
        float: "left",
        borderRadius: 8,
        border:
          window.innerWidth < 768
            ? "none"
            : `3px solid ${focused ? "rgba(6,209,6,.6)" : "rgba(0,0,0,0)"}`,
      }}
    >
      {React.cloneElement(children, { focused, clicked })}
    </div>
  );
};

export default FocusableComponent;
