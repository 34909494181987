import React from "react";
import Typewriter from "../components/Typewriter";

const LivestreamContainer: React.FC = () => {
  const getLivestreamUrl = () => {
    switch (process.env.REACT_APP_CLIENT) {
      case "NBC":
        return "http://wz3.newradio.it:1935/9050/9050/index.m3u8";

      case "NATURHOUSE":
        return "https://streaming.radioxstore.com/radio/Naturehouse_Elegant";

      case "RSP":
      default:
        return "https://5a1178b42cc03.streamlock.net:443/studiopiutv/studiopiutv/playlist.m3u8";
    }
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        overflowY: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ position: "relative", width: "100%", height: "auto" }}
          src="/images/branding/peperoni_bg.png"
          alt="Naturhouse"
        />
      </div>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "4rem",
          textAlign: "center",
          background:
            "linear-gradient(120deg,rgba(14,143,126,1) 0%,rgba(4,83,72,1) 4%,rgba(0,0,0,.9) 25%,rgba(0,0,0,.4) 50%,rgba(0,0,0,0) 100%)",
        }}
      >
        <Typewriter
          text={`Stiamo aggiornando il palinsesto per la nuova stagione!`}
          delay={90}
          onScrollTick={() => console.log()}
          onFinish={() => console.log()}
        />
      </div>

      {/* <ReactPlayer
        url={getLivestreamUrl()}
        width="100%"
        height="100%"
        playing
        muted={false}
      ></ReactPlayer> */}
    </div>
  );
};

export default LivestreamContainer;
