import React, { useState, useEffect, useRef } from "react";
import { Hero } from "../components";
import { usePlayer } from "../context/PlayerContext";
import { Profile, ShowOverview } from "../constants/types";
import ReactPlayer from "react-player";
import pepAxios from "../api/contents";
import { GENERAL_SECTIONS } from "../api/contentsEndpoints";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableComponent from "./focusableComponent";
import TranslatedText from "../components/TranslatedText";
import { ReducerGraphic, Reducers } from "../types/reducers";
import { useSelector } from "react-redux";
import { updateContentUrl } from "../resources/utility/functions";
import { InfoOutlined, PlayArrowRounded } from "@material-ui/icons";

type HeroContainerType = {
  profile?: Profile;
  activeCatalog: number;
};
function HeroContainer({ profile, activeCatalog }: HeroContainerType) {
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const [banner, setBanner] = useState<ShowOverview | null>();
  const playerRef = useRef<ReactPlayer>(null);
  const {
    category: { category },
    playing: { setPlaying },
    isMuted: { isMuted, setIsMuted },
    detailsTrailer: { detailsTrailer, setDetailsTrailer },
    heroTrailer: { heroTrailer, setHeroTrailer },
    detailsContent: { detailsContent, setDetailsContent },
  } = usePlayer();
  const { ref, focusKey } = useFocusable();
  const windowWidth = window.innerWidth;

  useEffect(() => {
    console.log("activeCatalog", activeCatalog);
  }, [activeCatalog]);

  useEffect(() => {
    if (activeCatalog < 0) return;
    fetchHeroContent();
  }, [activeCatalog, graphic.currentLanguage]);

  const fetchHeroContent = async () => {
    const bodyParams = {
      filters: {
        categories: [],
        tags: null,
        guests: null,
        query: "",
      },
    };

    const fetchedContents: any = await pepAxios
      .post(
        GENERAL_SECTIONS.helpers.fetchCategoryContents.replace(
          "{{catalog_id}}",
          activeCatalog.toString()
        ),
        bodyParams
      )
      .then((response) => response.data);
    const filteredContents = fetchedContents.filter(
      (content: any) =>
        content &&
        content.localized[graphic.currentLanguage] &&
        (content.localized[graphic.currentLanguage].audio_url ||
          content.localized[graphic.currentLanguage].video_url)
    );
    if (filteredContents.length === 0) {
      setHeroTrailer(null);
      setBanner(null);
      return;
    }
    const reccomendedItems = filteredContents.filter(
      (item: any) =>
        item.tags.findIndex((tag: any) => tag.value === "Recommended") !== -1
    );
    const selectedContents =
      reccomendedItems.length > 0 ? reccomendedItems : filteredContents;
    const selectedContentForHero =
      selectedContents[Math.floor(Math.random() * selectedContents.length)];
    setHeroTrailer(
      selectedContentForHero.localized[graphic.currentLanguage].video_url || ""
    );
    setBanner({
      id: selectedContentForHero.id,
      category:
        selectedContentForHero.categories.length > 0
          ? selectedContentForHero.categories[0].value
          : "",
      overview:
        selectedContentForHero.localized[graphic.currentLanguage].description ||
        "",
      poster_path: updateContentUrl(
        selectedContentForHero.localized[graphic.currentLanguage].img_url
      ),
      backdrop_path: updateContentUrl(
        selectedContentForHero.localized[graphic.currentLanguage].img_url
      ),
      genre_ids: [selectedContentForHero.id_content_type],
      popularity: 0,
      vote_average: 0,
      vote_count: 0,
      original_language: selectedContentForHero.locales,
      original_title:
        selectedContentForHero.localized[graphic.currentLanguage].title,
      title: selectedContentForHero.localized[graphic.currentLanguage].title,
      name: selectedContentForHero.localized[graphic.currentLanguage].title,
      original_name:
        selectedContentForHero.localized[graphic.currentLanguage].title,
      content_url: selectedContentForHero.localized[graphic.currentLanguage]
        .audio_url
        ? updateContentUrl(
            selectedContentForHero.localized[graphic.currentLanguage].audio_url
          )
        : updateContentUrl(
            selectedContentForHero.localized[graphic.currentLanguage].video_url
          ),
      content_audio_url:
        updateContentUrl(
          selectedContentForHero.localized[graphic.currentLanguage].audio_url
        ) || "",
      content_video_url:
        updateContentUrl(
          selectedContentForHero.localized[graphic.currentLanguage].video_url
        ) || "",
      content_categories: selectedContentForHero.categories.map(
        (cat: any) => cat.value
      ),
      catalog: activeCatalog,
      tags: selectedContentForHero.tags,
      release_date: selectedContentForHero.created,
    });
  };

  useEffect(() => {
    if (!banner) return;
    setDetailsContent(banner);
  }, [banner]);

  const truncate = (string: string, length: number) => {
    return string.length > length
      ? string.substr(0, length - 1) + "..."
      : string;
  };

  return (
    <Hero
      style={{
        minHeight: windowWidth <= 600 ? "60vh" : "90vh",
        backgroundColor: "rgb(0, 0, 0)",
      }}
    >
      {banner && (
        <React.Fragment>
          {banner.backdrop_path ? (
            <Hero.Banner src={banner.backdrop_path} windowWidth={windowWidth} />
          ) : (
            heroTrailer && (
              <Hero.Video
                style={{ minHeight: "70vh" }}
                playerRef={playerRef}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                heroTrailer={heroTrailer}
                setHeroTrailer={setHeroTrailer}
              />
            )
          )}
          <Hero.Overlay style={{ minHeight: "70vh" }} fullOverlay={false} />
          {banner.title && (
            <Hero.Details /** className={heroTrailer ? "no-desc" : ""} */>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  gap: 6,
                  fontSize: "1.6em",
                  letterSpacing: ".3px",
                }}
              >
                <div style={{ lineHeight: "100%", fontFamily: "MullerBold" }}>
                  NATUR HOUSE
                </div>
                <div style={{ lineHeight: "100%", fontFamily: "MullerLight" }}>
                  {(banner.category as string).toLocaleUpperCase()}
                </div>
              </div>
              <Hero.Title
                className="title-small" /** className={!heroTrailer ? "title-small" : ""} */
              >
                {truncate(
                  banner.name || banner.title || banner.original_name || "",
                  90
                )}
              </Hero.Title>
              <div className="hero-tags">
                {banner.tags.map((tag: any) => (
                  <label>{tag.value}</label>
                ))}
              </div>
              {banner.overview && windowWidth > 600 && (
                <Hero.Description>
                  {truncate(banner.overview, 80)}
                </Hero.Description>
              )}
              <FocusContext.Provider value={focusKey}>
                <div
                  ref={ref}
                  style={{
                    display: "inline-flex",
                    gap: "1rem",
                    margin: "1rem 0 0",
                  }}
                >
                  <FocusableComponent
                    scrollPageIsActive
                    enterPressed={() => {
                      setHeroTrailer();
                      setPlaying(banner);
                    }}
                  >
                    <Hero.Button
                      className="white-btn"
                      onClick={() => {
                        setHeroTrailer();
                        sessionStorage.setItem("playingElementRow", "[]");
                        setPlaying(banner);
                      }}
                    >
                      <PlayArrowRounded />{" "}
                      <span>
                        <TranslatedText translationKey="detail.play" />
                      </span>
                    </Hero.Button>
                  </FocusableComponent>
                  <FocusableComponent
                    scrollPageIsActive
                    enterPressed={() =>
                      setDetailsTrailer({
                        id: banner.id,
                        key: heroTrailer ? heroTrailer : null,
                        start:
                          playerRef && playerRef.current
                            ? playerRef.current.getCurrentTime()
                            : 0,
                      })
                    }
                  >
                    <Hero.Button
                      onClick={() => {
                        setDetailsTrailer({
                          id: banner.id,
                          key: heroTrailer ? heroTrailer : null,
                          start:
                            playerRef && playerRef.current
                              ? playerRef.current.getCurrentTime()
                              : 0,
                        });
                      }}
                    >
                      <InfoOutlined />{" "}
                      <span>
                        <TranslatedText translationKey="detail.details" />
                      </span>
                    </Hero.Button>
                  </FocusableComponent>
                </div>
              </FocusContext.Provider>
            </Hero.Details>
          )}
        </React.Fragment>
      )}
    </Hero>
  );
}

export default HeroContainer;
