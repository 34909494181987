import { useState, useEffect } from "react";

/**
 * Retrieves the selected locale from the URL query parameters.
 * If the locale parameter is present in the URL, it is returned.
 * Otherwise, the default locale "it" is returned.
 *
 * @returns The selected locale as a string.
 */
export const getSelectedLocale = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const localeParam = urlParams.get("locale");
  return localeParam || "it";
};

/**
 * Replaces the content prefix in a given URL with a cloudfront prefix.
 * @param contentUrl - The original URL with the content prefix.
 * @returns The updated URL with the content prefix replaced by the cloudfront prefix.
 */
export const updateContentUrl = (contentUrl: string): string => {
  const contentPrefix = "https://peperoni-contents.s3.eu-west-1.amazonaws.com/";
  const cloudfrontPrefix = "https://di6uk62dbzs7g.cloudfront.net/";
  return contentUrl.replace(contentPrefix, cloudfrontPrefix);
};

/**
 * Converts a given number of seconds into a time string in the format "HH:MM:SS".
 * @param givenSeconds - The number of seconds to be converted into a time string.
 * @returns The time string in the format "HH:MM:SS".
 */
export const convertSecondsToTimeString = (givenSeconds: number): string => {
  const dateObj = new Date(givenSeconds * 1000);
  const hours = dateObj.getUTCHours().toString().padStart(2, "0");
  const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");
  const seconds = dateObj.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}
