import React, { useState, useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";
import movieHttp from "../api/movie";
import { Loading } from "../components";
import {
  HeroContainer,
  SectionsContainer,
  ProfilesContainer,
  PlayerContainer,
  SearchContainer,
  DetailsContainer,
} from "../containers";
import { useUser } from "../context/UserContext";
import { PlayerContext } from "../context/PlayerContext";
import { SECTIONS } from "../api/movieEndpoints";
import {
  DetailsTrailer,
  Genre,
  Profile,
  ShowOverview,
  TrailerDisplayed,
} from "../constants/types";
import Drawer from "../components/drawer";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import LivestreamContainer from "../containers/livestream";
import NewsletterBanner from "../containers/newsletterBanner";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentNewsletterModal } from "../store/actions/graphic";
import { ReducerGraphic, Reducers } from "../types/reducers";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return isMobile;
};

const Browse: React.FC = () => {
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const { userDetails } = useUser();
  const [profile, setProfile] = useState<Profile | undefined>({
    _id: "-1",
    name: "testProfile",
    avatar: "/images/branding/Logo_nh_tv.png",
  });

  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState<ShowOverview | null | undefined>();
  const [detailsTrailer, setDetailsTrailer] = useState<
    DetailsTrailer | null | undefined
  >();
  const [heroTrailer, setHeroTrailer] = useState<string | null | undefined>();
  const [trailerDisplayed, setTrailerDisplayed] = useState<
    TrailerDisplayed | null | undefined
  >();
  const [isMuted, setIsMuted] = useState(true);
  const [category, setCategory] = useState("series");
  const [isHeaderShown, setHeaderShown] = useState(false);
  const [sectionDisplayed, setSectionDisplayed] = useState(5);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [livestreamIsActive, setLivestreamIsActive] = useState(false);
  const [newsletterBannerIsVisible, setNewsletterBannerIsVisible] =
    useState(false);
  const [activeCatalog, setActiveCatalog] = useState(-1);
  const [genres, setGenres] = useState<Array<Genre> | undefined | null>();
  const [detailsContent, setDetailsContent] = useState<any>();
  const { ref, focusKey } = useFocusable({ isFocusBoundary: true });
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [drawerCollapsed, setDrawerCollapsed] = useState(false);

  useEffect(() => {
    setNewsletterBannerIsVisible(graphic.showNewsletterModal);
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1600);
    return () => {
      if (!timeout) return;
      clearTimeout(timeout);
    };
  }, [graphic.showNewsletterModal]);

  useEffect(() => {
    try {
      const endpoint =
        category === "series"
          ? SECTIONS.series.helpers.fetchTVGenres
          : SECTIONS.movies.helpers.fetchMovieGenres;
      movieHttp
        .get(endpoint)
        .then((response) => setGenres(() => response.data.genres));
      setSectionDisplayed(5);
    } catch (err) {
      console.error(err);
    }
  }, [category, setSectionDisplayed]);

  const handleOnScroll = ({ clientHeight, scrollTop, scrollHeight }: any) => {
    const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight - 300;
    if (bottom) {
      setSectionDisplayed((sectionDisplayed) => {
        const newDisplayed = sectionDisplayed + 5;
        return newDisplayed <= SECTIONS[category].sections.length
          ? newDisplayed
          : sectionDisplayed;
      });
    }
    if (scrollTop > 100 && !isHeaderShown) {
      setHeaderShown(true);
    } else if (scrollTop <= 100 && isHeaderShown) {
      setHeaderShown(false);
    }
  };

  const renderScrollableContent = (children: React.ReactNode) => {
    if (isMobile) {
      return (
        <div
          className="main-scroll-container"
          style={{
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
            height: isMobile ? window.innerHeight : "auto",
          }}
          onScroll={(e) => {
            const target = e.target as HTMLElement;
            handleOnScroll({
              clientHeight: target.clientHeight,
              scrollTop: target.scrollTop,
              scrollHeight: target.scrollHeight,
            });
          }}
        >
          {children}
        </div>
      );
    } else {
      return (
        <Scrollbar
          tabIndex={-1}
          noDefaultStyles
          className="main-scrollbar"
          onScroll={(e: any) => handleOnScroll(e)}
        >
          {children}
        </Scrollbar>
      );
    }
  };

  return profile ? (
    <PlayerContext.Provider
      value={{
        playing: { playing, setPlaying },
        detailsTrailer: { detailsTrailer, setDetailsTrailer },
        isMuted: { isMuted, setIsMuted },
        category: { category, setCategory },
        heroTrailer: { heroTrailer, setHeroTrailer },
        trailerDisplayed: { trailerDisplayed, setTrailerDisplayed },
        genres: { genres, setGenres },
        detailsContent: { detailsContent, setDetailsContent },
      }}
    >
      {renderScrollableContent(
        <FocusContext.Provider value={focusKey}>
          <div
            className="browse"
            ref={ref}
            style={{
              marginLeft: isMobile ? "-160px" : 0,
              transition: "margin-left 0.3s ease",
            }}
          >
            {loading && profile ? (
              <Loading src={`${profile.avatar}`} />
            ) : (
              <Loading.ReleaseBody />
            )}
            {!!detailsTrailer && !(playing as any)?.content_video_url && (
              <DetailsContainer />
            )}
            {newsletterBannerIsVisible && (
              <NewsletterBanner
                close={() => {
                  dispatch(setCurrentNewsletterModal(false));
                  setNewsletterBannerIsVisible(false);
                }}
              />
            )}
            <Drawer
              setFocusFlag={
                ((playing === undefined && detailsTrailer === undefined) ||
                  (playing &&
                    playing.id === detailsContent?.id &&
                    detailsContent?.content_audio_url)) &&
                !newsletterBannerIsVisible
              }
              profile={profile}
              setActiveCatalog={setActiveCatalog}
              activeCatalogId={activeCatalog}
              setCategory={setCategory}
              activeCategory={category}
              setSearchIsActive={setSearchIsActive}
              setLivestreamIsActive={setLivestreamIsActive}
              clearCurrentUser={() => setProfile(undefined)}
              onCollapseChange={(collapsed) => {
                setDrawerCollapsed(collapsed);
              }}
            />
            <div className="main">
              {searchIsActive ? (
                <SearchContainer activeCategory={category} />
              ) : livestreamIsActive ? (
                <LivestreamContainer />
              ) : (
                <>
                  <div style={{ marginBottom: isMobile ? 0 : 0 }}>
                    <HeroContainer
                      profile={profile}
                      activeCatalog={activeCatalog}
                    />
                  </div>
                  <div className="main-content" style={{}}>
                    {activeCatalog !== -1 && (
                      <SectionsContainer
                        catalog={activeCatalog}
                        category={category}
                        sectionDisplayed={sectionDisplayed}
                      />
                    )}
                  </div>
                </>
              )}
              {!!playing &&
                detailsContent?.content_video_url &&
                !playing?.content_audio_url && (
                  <PlayerContainer
                    playing={playing}
                    setPlaying={(playingMeta) => {
                      setPlaying(playingMeta);
                      if (!playingMeta) {
                        setTrailerDisplayed(undefined);
                        setDetailsTrailer(undefined);
                        sessionStorage.setItem("playingElementRow", "[]");
                      }
                    }}
                  />
                )}
            </div>
          </div>
        </FocusContext.Provider>
      )}
    </PlayerContext.Provider>
  ) : (
    <ProfilesContainer
      userDetails={userDetails}
      setProfile={(profile) => {
        setProfile(profile);
      }}
    />
  );
};

export default Browse;
