import styled from "styled-components/macro";
import { Row as ShowRow, CardContainer } from "../../show/styles/show";

export const Container = styled.div`
  position: relative;
  width: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: calc(100% - 50px);
  }
`;
export const Row = styled(ShowRow)`
  display: flex;
  padding: 4rem 3rem 8rem 110px;
  grid-gap: 0.5rem;
  margin-top: 0;
  margin-left: 0;
  flex-wrap: wrap;

  ${CardContainer} {
    padding-bottom: 4rem;
    height: 10rem;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    padding-left: 0;
    margin-left: 100px;
    ${CardContainer} {
      padding-bottom: 2rem;
      height: 10rem;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(10.5rem, 1fr));
    padding: 5rem 2rem;
    ${CardContainer} {
      padding-bottom: 0;
      height: 10rem;
    }
  }
`;

export const SearchHeader = styled.div`
  position: relative;
  width: 50%;
  margin: 5.45rem auto 0;
  div {
    width: 100%;
  }
`;
export const NoMatch = styled.div`
  text-align: center;
  margin: 10rem auto;
  max-width: 30rem;
  line-height: 1.5;
  p {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 600px) {
    max-width: 15rem;
    font-size: 0.8rem;
  }
`;
