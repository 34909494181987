import { ComponentProp } from "../../constants/types";
import { useIsMobile } from "../../resources/utility/functions";
import { Spinner, LockBody, Picture, ReleaseBody } from "./styles/loading";

function Loading({ src, ...restProps }: ComponentProp) {
  const isMobile = useIsMobile();
  return (
    <Spinner {...restProps} style={{ right: isMobile ? "0%" : "unset" }}>
      <LockBody />
      <Picture src={`${src}`} />
    </Spinner>
  );
}
Loading.ReleaseBody = function LoadingReleaseBody() {
  return <ReleaseBody />;
};

export default Loading;
